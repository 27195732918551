const Colors = {
    white: '#ffffff',
    light: '#45BFEE',
    lightGrey: '#45BFEE',
    dark: '#00171F',
    darker: '#007EA7',
    darkest: '#00171F',
    closeIcon: '#00171F'
  }
  
  export default Colors;